import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectActiveLocationsListData, selectNearestLocationsData } from './selectors';
import ActiveLocationsAdvancedFilter from './ActiveLocationsAdvancedFilter/ActiveLocationsAdvancedFilter';
import MapComponent from 'components/MapComponent/MapComponent';
import ActiveLocationsFilterSidebar from './ActiveLocationsAdvancedFilter/ActiveLocationsFilterSidebar';
import { clearLocationState, getNearestMapLocations } from 'actions/locationsActions';
import { Button } from '@material-ui/core';
import styles from './BuildRouteMap.module.scss';
import { useMobileViewport } from 'hooks/useMobileViewport';

function BuildRouteMap({
  nearestLocationsData,
  activeLocationsListData,
  getNearestMapLocationsAction,
  clearActiveLocationsListAction
}) {
  const isMobile = useMobileViewport();
  const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);
  const [customMarker, setCustomMarker] = useState({});
  const [showSidebar, setShowSidebar] = useState(!isMobile);
  const [advancedFilter, setAdvancedFilter] = useState({
    asset: { selectedTab: 'location', siteType: { id: 1, name: 'Storage Yard' } },
    looseItem: { selectedTab: 'location', siteType: { id: 1, name: 'Storage Yard' } },
    selectedPanelTab: 0
  });

  const isAdvancedFilterApplied = () =>
    (!!Object.keys(advancedFilter?.asset).length ||
      !!Object.keys(advancedFilter?.looseItem).length) &&
    !!nearestLocationsData?.nearestLocations?.length;

  useEffect(() => {
    return () => {
      clearActiveLocationsListAction();
    };
  }, []);

  const onAdvancedFilterOpen = (state) => {
    setOpenAdvancedFilter(state);
  };

  return (
    <div className={styles.mapContainer}>
      <section className={styles.mapContainer__wrapper}>
        <ActiveLocationsAdvancedFilter
          filter={advancedFilter}
          setFilter={setAdvancedFilter}
          setCustomMarker={setCustomMarker}
          isMobile={isMobile}
          openFilter={openAdvancedFilter}
          setOpenFilter={onAdvancedFilterOpen}
        />

        {isMobile && (
          <section className={styles.headerWrapper}>
            <div className={styles.pageHeader}>
              <h1>Map</h1>
            </div>
            {isAdvancedFilterApplied() && !showSidebar && (
              <Button className={styles.showMoreButton} onClick={() => setShowSidebar(true)}>
                View results
              </Button>
            )}
          </section>
        )}

        <MapComponent
          locations={activeLocationsListData?.items?.length ? activeLocationsListData.items : []}
          advancedFilterApplied={isAdvancedFilterApplied()}
          nearestLocationsData={nearestLocationsData}
          customMarker={customMarker}
        />
      </section>
      {isAdvancedFilterApplied() && showSidebar && (
        <ActiveLocationsFilterSidebar
          filter={advancedFilter}
          setFilter={setAdvancedFilter}
          nearestLocationsData={nearestLocationsData}
          getNearestMapLocationsAction={getNearestMapLocationsAction}
          hideSidebar={() => setShowSidebar(false)}
          isMobile={isMobile}
        />
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  activeLocationsListData: selectActiveLocationsListData(),
  nearestLocationsData: selectNearestLocationsData()
});

const mapDispatchToProps = {
  getNearestMapLocationsAction: getNearestMapLocations,
  clearActiveLocationsListAction: clearLocationState
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BuildRouteMap);
