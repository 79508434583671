import React, { forwardRef, useRef } from 'react';
import styles from './FormDayPicker.module.scss';

import { Controller, useFormContext } from 'react-hook-form';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FormFieldError } from 'components/FormComponents';

import {
  datePickerFormat,
  formatDate,
  getMonthYear,
  getWeekRange,
  getYear,
  getStartDayOfWeek
} from 'helpers/AppHelpers';

import EventIcon from '@material-ui/icons/Event';
import { IconButton } from '@material-ui/core';

import clsx from 'clsx';

import moment from 'moment';

export default function FormDayPicker({
  name,
  format,
  disabled,
  min,
  max,
  clearable = true,
  showWeekPicker = false,
  showMonthYearPicker = false,
  showYearPicker = false,
  onSelectTriggered
}) {
  const pickerRef = useRef(null);
  const { control, setValue, clearErrors } = useFormContext();

  const handleDateChange = (date, isInvalid) => {
    isInvalid && clearErrors(name);
    const value = date
      ? showWeekPicker
        ? formatDate(getStartDayOfWeek(date), format)
        : formatDate(date, format)
      : null;
    setValue(name, value, { shouldDirty: true });
    onSelectTriggered && onSelectTriggered(name, value);
  };

  const clearDate = () => {
    setValue(name, null, { shouldDirty: true });
    onSelectTriggered && onSelectTriggered(name, null);
    pickerRef.current.setOpen(false);
  };

  const generateDisplayedFieldValue = (value) => {
    if (!value || !moment(value).isValid()) {
      return '';
    }

    if (showWeekPicker) return getWeekRange(value);
    if (showMonthYearPicker) return getMonthYear(value);
    if (showYearPicker) return getYear(value);
    return value;
  };

  const PickerInput = forwardRef(function Field({ value, onClick, isInvalid }, ref) {
    return (
      <div
        className={clsx(styles.field, disabled && styles.disabled, isInvalid && styles.invalid)}
        ref={ref}>
        <div className={styles.field__value}>{generateDisplayedFieldValue(value)}</div>
        <div className={styles.field__controls}>
          <IconButton onClick={onClick} className={styles.event}>
            <EventIcon />
          </IconButton>
        </div>
      </div>
    );
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, fieldState: { error } }) => {
        const localValue = value
          ? moment(typeof value === 'number' ? value.toString() : value).toDate()
          : null;
        localValue && localValue.setHours(0, 0, 0, 0);
        return (
          <div className={styles.inputWrapper}>
            <DatePicker
              ref={pickerRef}
              selected={localValue}
              onChange={(date) => handleDateChange(date, !!error?.message)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              showWeekPicker={showWeekPicker}
              showMonthYearPicker={showMonthYearPicker}
              showYearPicker={showYearPicker}
              closeOnScroll
              dropdownMode="select"
              dateFormat={datePickerFormat}
              disabledKeyboardNavigation
              calendarClassName={styles.picker}
              dayClassName={() => styles.calendarDay}
              minDate={min ? moment(min).toDate() : new Date('2000-01-01')}
              maxDate={max || new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
              customInput={<PickerInput isInvalid={!!error?.message} />}
              popperClassName={styles.popperClassName}
              popperProps={{ strategy: 'fixed' }}>
              {clearable && (
                <IconButton className={styles.clearButton} onClick={clearDate}>
                  Clear
                </IconButton>
              )}
            </DatePicker>
            {!!error && <FormFieldError>{error.message}</FormFieldError>}
          </div>
        );
      }}
    />
  );
}
