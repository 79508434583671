import React from 'react';
import styles from './PopupContent.module.scss';

import StyledLink from 'components/StyledLink';
import { IconButton } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';

import { LOCATION_DETAILED_PATH } from 'constants/routeConstants';

import clsx from 'clsx';

const Label = ({ children }) => <label className={styles.label}>{children}</label>;

const Value = ({ onClick, children }) => (
  <span className={clsx(styles.value, onClick && styles.clickable)} onClick={onClick}>
    {children}
  </span>
);

const Pair = ({ children }) => <div className={styles.pair}>{children}</div>;

const Direction = ({ lat, long }) => (
  <a
    className={styles.link}
    target="_blank"
    rel="noreferrer"
    href={`https://www.google.com/maps/place/${lat},${long}/@${lat},${long},13z`}>
    To here
  </a>
);

const AssetCount = ({ count, onClick }) => <Value onClick={onClick}>{count} Assets</Value>;

const LooseItemCount = ({ count, onClick }) => <Value onClick={onClick}>{count} Loose Items</Value>;

const MoreButton = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <ArrowRight />
  </IconButton>
);

export const PopupContent = ({ info, onClick }) => {
  const {
    id,
    siteCode,
    siteType,
    locationJobNumber,
    assetsCount,
    looseItemsCount,
    latitude,
    longitude
  } = info;
  const handleCountClick = (type) => onClick(type, info);

  return (
    <div className={styles.content}>
      <StyledLink bold to={`${LOCATION_DETAILED_PATH}/${id}`}>
        {siteCode || ''}
      </StyledLink>

      {!!siteType?.name && <Value>{siteType.name}</Value>}

      {!!locationJobNumber && (
        <Pair>
          <Label>Job Number:</Label>
          <Value>{locationJobNumber}</Value>
        </Pair>
      )}

      {(!!assetsCount || !!looseItemsCount) && <Label>Items at the Location:</Label>}

      {!!assetsCount && (
        <Pair>
          <AssetCount count={assetsCount} />
          <MoreButton onClick={() => handleCountClick('ASSETS')} />
        </Pair>
      )}

      {!!looseItemsCount && (
        <Pair>
          <LooseItemCount count={looseItemsCount} />
          <MoreButton onClick={() => handleCountClick('LOOSE_ITEMS')} />
        </Pair>
      )}

      <Pair>
        <Value>Directions:</Value>
        <Direction lat={latitude} long={longitude} />
      </Pair>
    </div>
  );
};
