export const SUCCESS_CREATE_CATEGORY = 'The category successfully created';
export const SUCCESS_UPDATE_CATEGORY = 'The category successfully updated';
export const SUCCESS_DELETE_CATEGORY = 'Category successfully deleted';

export const ERROR_PREFIX_EXIST = 'The field with such name already exists';

export const ERROR_EMAIL_EXIST = 'The email with such name already exists';

export const ERROR_ENTITY_EXIST = 'The entity with such name already exists';

export const ERROR_INVALID_EMAIL = 'Invalid email';

export const SUCCESS_CREATE_PREFIX = 'Prefix successfully created';
export const SUCCESS_UPDATE_PREFIX = 'Prefix successfully updated';
export const SUCCESS_DELETE_PREFIX = 'Prefix successfully deleted';

export const SUCCESS_CREATE_LOCATION = 'The location successfully created';
export const SUCCESS_UPDATE_LOCATION = 'The location successfully updated';
export const SUCCESS_DELETE_LOCATION = 'The location successfully deleted';

export const SUCCESS_CREATE_REPORT_TEMPLATE = (name) => `The report template "${name}" is created`;

export const SUCCESS_CREATE_PROJECT = 'The project successfully created';
export const SUCCESS_UPDATE_PROJECT = 'The project successfully updated';
export const SUCCESS_DELETE_PROJECT = 'The project successfully deleted';

export const SUCCESS_UPDATE_PROJECT_LOCATIONS =
  'Locations list for reports was successfully updated';

export const SUCCESS_UPDATE_REPORT_TEMPLATE = (name) => `The report template "${name}" is saved`;
export const SUCCESS_PUBLISH_REPORT_TEMPLATE = (name) =>
  `The report template "${name}" is published`;
export const SUCCESS_DELETE_REPORT_TEMPLATE = 'The report template successfully deleted';

export const SUCCESS_SAVE_REPORT = 'The report successfully saved';
export const SUCCESS_SUBMIT_REPORT = 'The report successfully submitted';
export const SUCCESS_DELETE_GENERAL_REPORT = 'The report successfully deleted';

export const SUCCESS_CREATE_ASSET = 'The asset successfully created';
export const SUCCESS_UPDATE_ASSET = 'The asset successfully updated';
export const SUCCESS_DELETE_ASSET = 'The asset successfully deleted';

export const SUCCESS_INSPECTED_STATUS = 'Marked as inspected and verified';
export const SUCCESS_NEED_TO_BE_INSPECTED_STATUS = 'Asset Needs to be inspected';
export const SUCCESS_VERIFIED_STATUS = 'Marked as verified';

export const SUCCESS_UPDATE_USER = 'The user successfully updated';
export const SUCCESS_DELETE_USER = 'The user successfully deleted';

export const SUCCESS_CREATE_TICKET = 'The ticket successfully created';
export const SUCCESS_UPDATE_TICKET = 'The ticket successfully updated';
export const SUCCESS_DELETE_TICKET = 'The ticket successfully deleted';

export const SUCCESS_UPDATE_ASSETS_FIELDS = `Asset's fields successfully updated`;

export const SUCCESS_ADD_ASSET_TICKETS = 'Tickets are successfully created';

export const SUCCESS_UPDATE_ROOT_CAUSE = 'The root cause successfully saved';
export const SUCCESS_CREATE_ROOT_CAUSE = 'The root cause successfully created';

export const SUCCESS_UPDATE_TICKET_TYPE = 'The ticket type successfully saved';
export const SUCCESS_CREATE_TICKET_TYPE = 'The ticket type successfully created';

export const SUCCESS_CREATE_LOOSE_ITEM = 'Loose item successfully created';
export const SUCCESS_UPDATE_LOOSE_ITEM = 'Loose item successfully updated';
export const SUCCESS_DELETE_LOOSE_ITEM = 'Loose item successfully deleted';

export const SUCCESS_CREATE_TENT_CONFIGURATION = 'Configuration successfully created';
export const SUCCESS_UPDATE_TENT_CONFIGURATION = 'Configuration successfully updated';
export const SUCCESS_DELETE_TENT_CONFIGURATION = 'Configuration successfully deleted';

export const SUCCESS_CREATE_ASSEMBLE = 'The assemble successfully packed';
export const SUCCESS_DELETE_ASSEMBLY = 'The assemble successfully deleted';

export const SUCCESS_CREATE_PICKLIST = 'The picklist successfully created';
export const SUCCESS_UPDATE_PICKLIST = 'The picklist successfully updated';
export const SUCCESS_DELETE_PICKLIST = 'The picklist successfully deleted';

export const SUCCESS_CREATE_LEAVELIST = 'The leavelist successfully created';
export const SUCCESS_UPDATE_LEAVELIST = 'The leavelist successfully updated';
export const SUCCESS_DELETE_LEAVELIST = 'The leavelist successfully deleted';

export const SUCCESS_CREATE_TEAM_MEMBER = 'The team member successfully created';
export const SUCCESS_UPDATE_TEAM_MEMBER = 'The team member successfully updated';
export const SUCCESS_DELETE_TEAM_MEMBER = 'The team member successfully deleted';

export const SUCCESS_RENTAL_STATUS_UPDATE = 'Rental status was updated';

export const SUCCESS_SEND_GROUP_MESSAGE = 'Message sent';

export const SUCCESS_MOVED_LOOSE_ITEMS = 'The loose items are successfully moved';
export const ERROR_MOVED_LOOSE_ITEMS = 'There is no available quantity for this loose item';

export const SUCCESS_CREATE_LOOSE_ITEMS = 'The loose items are successfully created';

export const TRY_AGAIN_MESSAGE = 'Error! Please try again';

export const ERROR_FIND_ASSET = 'Error! Asset was not found';

export const WARNING_LOOSE_ITEMS_WILL_BE_MOVED =
  'Loose items from Buy Ahead Account will be moved with Move Total QTY loose items.';

export const ERROR_LOOSE_ITEM_VALIDATE_TABLE =
  'The “Buy Ahead Account” value must not exceed “Current QTY” value.';

export const ERROR_FIND_LOCATIONS = `There's no locations for such filter`;

export const ERROR_FIND_LOCATION = `Can't find location`;
export const ERROR_LESS_AMOUNT = 'The amount found is less than the requested amount';

export const SUCCESS_CREATE_REQUEST_ORDER = 'The request successfully created';

export const SUCCESS_DELETE_BILL_OF_LADING = 'The Bill of Lading successfully deleted';

export const SUCCESS_CANCEL_SCHEDULE_LAST_ACTION = 'Action successfully canceled';
export const ERROR_NO_OPPORTUNITY_TO_CANCEL_LAST_ACTION = 'There is nothing to cancel';

export const SUCCESS_CREATE_BATCH_OF_ASSETS = 'Assets are successfully created';

export const ERROR_BAA_EXCEED_QUANTITY =
  'The “Buy Ahead Account” value must not exceed “Quantity” value.';

export const SUCCESS_ADD_LOOSE_ITEMS_TO_PICKLIST =
  'The loose items are successfully added to the picklist';

export const SUCCUESS_ADD_ASSETS_TO_LEAVELIST =
  'The assets are successfully added to the leavelist';

export const SUCCESS_EDIT_LOOSE_ITEMS_WITHIN_PICKLIST =
  'The loose items are edited successfully within the picklist';

export const SUCCESS_UPDATE_ASSET_LOCATION = `Asset's location successfully updated to`;

export const SUCCESS_UPDATE_ASSETS_STATUS = `Status of Asset(s) successfully updated to`;
export const ERROR_UPDATE_ASSET_STATUS = `Asset(s) status wasn’t updated, as it in picklist.`;

export const ERROR_IMPORT_LOOSE_ITEMS = `Can't create loose items`;

export const ERROR_CANT_FIND_ASSETS = `Can't find assets:`;

export const WARNING_LOOSE_ITEMS_WILL_BE_MOVED_WITH_QTY =
  'Loose items from Buy Ahead Account will be moved with Add QTY loose items.';

export const ERROR_BAA_MUST_NOT_EXCEED_QTY =
  'The “Buy Ahead Account” value must not exceed  “Quantity” value.';

export const ERROR_ISSUE_SAVING_DATA_TO_SERVER =
  'Sorry, there was an issue saving your data to the server. Auto-saving has been disabled to prevent further issues. Please try manual save';

export const SUCCESS_TRAININGS_WERE_UPDATED =
  'Training and Certifications were successfully updated';

export const SUCCESS_TRAINING_WAS_DELETED = 'Training and Certification was successfully removed';

export const SUCCESS_CREATE_BOL_LOG = `The BOL's Log has been added successfully`;
export const SUCCESS_UPDATE_BOL_LOG = `The BOL's Log has been updated successfully`;
export const SUCCESS_DELETE_BOL_LOG = `The BOL's Log has been deleted successfully`;

export const NO_LOCATIONS_ARE_FOUND = `No locations are found`;
